<template>
  <div class="container" v-if="!isLoading">
    <div class="card-list">
      <div class="card bind-steam-card" v-if="!player || !player.steam_account">
        <div class="card-content">
          <router-link :to="{ name: 'BindSteam' }"> <van-button block class="blue-btn" type="primary">绑定SteamID</van-button></router-link>
          <p>绑定SteamID后即可解锁个人游戏数据功能</p>
        </div>
      </div>

      <template v-else>
        <div class="card" v-for="data in gameData" :key="data.project">
          <div class="card-header">
            <div class="game-logo">
              <img :src="data.logo" alt="" />
            </div>
            <div class="game-name">{{ data.name }}</div>
          </div>
          <div class="card-content">
            <div class="data-item">
              <div class="data-value">{{ data.playLevel && data.playLevel.level ? data.playLevel.level : '-' }}</div>
              <div class="data-label">档案等级</div>
            </div>
            <div class="data-item">
              <div class="data-value">{{ data.playBattlepass && data.playBattlepass.level ? data.playBattlepass.level : '-' }}</div>
              <div class="data-label">赛季等级</div>
            </div>
            <div class="data-item">
              <div class="data-value">{{ data.playRank && data.playRank.user.rank ? data.playRank.user.rank : '-' }}</div>
              <div class="data-label">赛季排名</div>
            </div>
            <div class="data-item">
              <div class="data-value">{{ data.playBehaviors && data.playBehaviors.settlement_counts ? data.playBehaviors.settlement_counts : '-' }}</div>
              <div class="data-label">赛季场数</div>
            </div>
            <div class="data-item">
              <div class="data-value">{{ data.playBehaviors && data.playBehaviors.win_counts ? data.playBehaviors.win_counts : '-' }}</div>
              <div class="data-label">胜利次数</div>
            </div>
            <div class="data-item">
              <div class="data-value">{{ data.playBehaviors && data.playBehaviors.play_duration ? data.playBehaviors.play_duration : '-' }}</div>
              <div class="data-label">游玩时间(小时)</div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'GameData',
}
</script>
<script setup>
import { Button } from 'vant'
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { playerData } from '@/api/gameData'
const VanButton = Button
const store = useStore()

onMounted(() => {
  initData()
})

const isLoading = ref(true)
const player = ref({})
const gameData = ref([])

const initData = () => {
  store.dispatch('player/getInfo').then(res => {
    player.value = res.data.player
    isLoading.value = false
  })
  getPlayerData()
}

const getPlayerData = () => {
  playerData().then(res => {
    gameData.value = res.data.gameData
  })
}
</script>

<style lang="scss" scoped>
.card-list {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.card {
  box-sizing: border-box;
  margin: 20px 0;
  width: 90vw;
  padding: 10px 20px;
  display: inline-block;
  border-radius: 10px;
  position: relative;
  text-align: center;
  /*box-shadow: -1px 15px 30px -12px black;*/
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 20px 50px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
  background: #2d2d35;
}

.card-header {
  padding-bottom: 10px;
  display: flex;
  border-radius: 10px 10px 0 0;
  align-items: center;
  border-bottom: 1px solid #424450;
}

/*.card-header:after {*/
/*    font-family: "iconfont";*/
/*    content: "\e666";*/
/*    font-weight: 600;*/
/*    color:#b0b5cb;*/
/*    margin-left: auto;*/
/*}*/

.game-logo {
  width: 22px;
  height: 22px;
  border-radius: 5px;
  border: 2px solid #fff;
}

.game-logo img {
  border-radius: 5px;
  width: 100%;
}

.game-name {
  margin-left: 10px;
  font-size: 15px;
  line-height: 100%;
  color: #fff;
  font-weight: 600;
}

.card-content {
  display: flex;
  justify-content: space-around;
  color: #fff;
  flex-wrap: wrap;
}

.card-content .data-item {
  padding-top: 5px;
  text-align: center;
  width: 33.3%;
  position: relative;
}

.card-content .data-label {
  font-size: 12px;
  font-weight: 600;
  color: #7c8197;
}

.card-content .data-value {
  font-size: 15px;
  font-weight: 500;
  color: #d2d8e8;
}

.bind-steam-card {
  padding: 50px 0;
  position: relative;
}

.bind-steam-card::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url('/images/common/tui6-logo.jpg') no-repeat center;
  background-size: cover;
  opacity: 0.3;
  filter: blur(3px);
}

.bind-steam-card .blue-btn {
  border-radius: 20px;
  width: 200px;
}

.bind-steam-card p {
  color: #b0b4c9;
  font-size: 12px;
  padding-top: 25px;
}
</style>
